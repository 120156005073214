/* @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap'); */

@font-face {
  font-family: 'SedanSC';
  src: url('./assets/font/sedan-sc/SedanSC-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/font/montserrat/montserrat.regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: unset;
  line-height: unset;
  transition: all 0.5s ease;
}

*:hover {
  color: unset;
  border: unset;
}

body {
  background-color: #151521;
  color: white;
}

::-webkit-scrollbar {
  display: none;
}

/* ::-webkit-scrollbar {
  width: 10px;
  border-radius: 50%;
}

::-webkit-scrollbar-thumb {
  background: #1263f1;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #dddddd;
  box-shadow: rgb(2 17 39) 0px 0px 5px inset;
  border-radius: 5px;
} */

.page-content {
  padding-top: 100px;
  padding-left: 250px;
  color: #dddddd;
  font-family: "Montserrat";
  font-size: 16px;
}

@media screen and (max-width: 900px) {
  .page-content {
    padding-top: 80px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 900px) {
  .desktop {
    display: none !important;
  }
}

@media screen and (min-width: 900px) {
  .mobile {
    display: none !important;
  }
}
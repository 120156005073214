@import "../config";

.launchpad-card {
  border-radius: 30px;
  padding: 25px;
  min-width: 320px;
  border: 2px transparentize($grey, 0.8) solid;
  border-radius: 15px;
  box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

  .avatar-sale {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .avatar {
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }

    .sale-live {
      display: flex;
      align-items: center;
      height: 26px;
      border-radius: 13px;
      padding: 0px 8px;
      background-color: rgb(209, 250, 229);
      color: rgb(16, 185, 129);
      font-size: $fsize6;
      font-weight: 600;

      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: rgb(16, 185, 129);
        margin-right: 5px;
      }
    }

    .sale-filled {
      @extend .sale-live;
    }

    .sale-ended {
      @extend .sale-live;
      background-color: #feeaf1;
      color: #f95192;

      span {
        background-color: #f95192;
      }
    }

    .sale-upcomming {
      @extend .sale-live;
      background-color: rgb(253, 250, 234);
      color: rgb(210, 152, 19);

      span {
        background-color: rgb(210, 152, 19);
      }
    }

    .sale-canceled {
      @extend .sale-live;
      background-color: rgb(233, 234, 235);
      color: rgb(189, 194, 196);

      span {
        background-color: rgb(189, 194, 196);
      }
    }
  }

  .progress-bar {
    width: 100%;
    position: relative;
    height: 8px;
    border-radius: 4px;
    border: 1px solid #aaa;

    .progress {
      position: absolute;
      left: 0;
      // top: 0;
      background-color: rgb(16, 185, 129);
      height: 100%;
    }
  }

  .title {
    margin-top: 25px;
    font-size: $fsize4;
    font-weight: 600;
    color: white;
  }

  .coin-fair {
    font-size: $fsize6;
    color: white;
    margin-top: 5px;
  }

  .soft-hard {
    margin-top: 30px;
    font-size: $fsize5;
    font-weight: 600;
    color: white;
  }

  .price {
    font-size: $fsize4;
    color: $blue;
    font-weight: 600;
    margin-top: 5px;
  }

  .progress-number {
    @extend .soft-hard;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .price-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;

    div {
      font-size: $fsize7;
      color: white;
    }
  }

  .liqudity {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin-top: 0px;
      font-size: $fsize5;
      color: white;
    }

    .subtitle {
      font-size: $fsize6;
      color: white;
    }
  }

  .lock {
    @extend .liqudity;
    margin-top: 5px;
  }

  .divider {
    margin-top: 5px;
    width: 100%;
    height: 0.5px;
    background-color: $grey;
  }

  .time-view {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .time-state {
      font-size: $fsize5;
      color: white;
    }

    .time {
      @extend .time-state;
      font-weight: bold;
    }
  }
}
@import "../config";

.header {
    color: white;
    font-size: $fsize3;
    background: none; 
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 40;

    .header-right {
        position: absolute;
        padding: 0 20px;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}

.fixed-header {
    @extend .header;
    background-color: #000000aa; 
}

.header-mobile {
    color: white;
    font-size: $fsize3;
    background-color: #000000aa; 
    width: 100%;
    height: 64px;
    position: fixed;
    top: 0;

    .header-right {
        position: absolute;
        padding: 0 20px;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    }
}
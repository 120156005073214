.mobile-menu {
    position: fixed;
    padding: 0 20px;
    left: 0;
    top: 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 50;
}

.sidebar {
    background-color: #0c0c12;
    width: 180px;
    padding: 0 25px;
    position: fixed;
    left: 0px;
    top: 0px;
    height: -webkit-fill-available;
    z-index: 50;
    display: flex;
    flex-direction: column;
    overflow: initial;
    transition: width 0.5s;
    @media screen and (max-width: 900px) {
        top: 64px;        
    }

    .toggle-btn-container {
        position: absolute;
        top: 35px;
        right: -16px;

        .toggle-btn {
            visibility: hidden;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #313539;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background .3s;
        }

        &:hover {
            .toggle-btn {
                visibility: visible;
            }

        }
    }

    .sidebar-top {
        padding: 15px 0;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        a {
            img {
                width: 50px;
                height: 50px;
            }
        }

        p {
            width: 120px;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            font-family: 'Oleo Script Swash Caps', cursive;
            color: #fff;
        }
    }

    .sidebar-divline {
        width: 100%;
        background-image: linear-gradient(to right, rgba(52, 71, 103, 0), #ffffff, rgba(52, 71, 103, 0)) !important;
        height: 1px;
        opacity: 0.3;
    }
}

.mini-sidebar {
    @extend .sidebar;
    width: auto;
    padding: 0 15px;

    .sidebar-top {
        p {
            display: none;
        }
    }
}
@import "../config";

.switch-network-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000090;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";

    .switch-network-window {
        width: 600px;
        background-color: #fff;
        z-index: 100;
        border-radius: 15px;
        color: black;
        font-size: $fsize4;
        box-shadow: 0 0 0 100vmax #00000080;

        @media screen and (max-width: 900px) {
            width: 90%;
        }

        .title {
            padding: 16px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                width: 25px;

                &:hover {
                    background: $grey;
                    transition: background-color 0.2s ease-in-out 0s;
                }
            }
        }


        .divider {
            width: 100%;
            height: 5px;
            background-color: black;
        }

        .content {
            padding: 24px;
            display: flex;
            justify-content: center;
            gap: 15px;

            @media screen and (max-width: 900px) {
                padding: 18px;
            }

            .network {
                width: 250px;
                height: 120px;
                padding: 20px 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 2px $grey solid;
                border-radius: 15px;
                transition: background-color 0.2s ease-in-out 0s;

                @media screen and (max-width: 900px) {
                    height: 80px;
                    font-size: $fsize6;
                }

                &:hover {
                    background: transparentize($blue, 0.6);
                    border: 2px $blue solid;
                }

                img {
                    width: 75px;
                    height: 75px;

                    @media screen and (max-width: 900px) {
                        width: 50px;
                        height: 50px;
                    }
                }

                div {
                    text-align: center;
                }
            }


        }
    }

}
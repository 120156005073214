@import "../config";

.switch-network-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 30px;
        height: 30px;
    }
}

.connect-wallet-btn {
    position: relative;
    font-size: $fsize6;
    font-weight: 500;
    font-family: "Montserrat";

    .main-button {
        
    }

    .dropdown {
        width: 100%;
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        box-shadow: 0px 8px 16px 0px #777;

        div {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;

            &:hover {
                background-color: $grey;
            }
        }
    }

    &:hover {
        .dropdown {
            display: block;
        }
    }
}


@import "../config";

.create-staking-pool {
    width: min(90%, 1500px);
    max-width: 1500px;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 80px;

    @media screen and (max-width: 900px) {
        margin-top: 10px;
    }

    .title {
        font-size: $fsize1;

        @media screen and (max-width: 900px) {
            text-align: center;
        }
    }

    .form {
        margin-top: 30px;
        padding: 28px 36px;
        border: 2px transparentize($grey, 0.8) solid;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: flex-start;
        gap: 15px;
        box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

        .div-line {
            width: 100%;
            background-color: $grey;
            height: 1px;
            opacity: 0.3;
        }

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .warning-text {
            color: $pink;
        }

        .tips-text {
            color: $blue;
        }

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: $pink;
            }
        }

        .text-input-group {
            display: flex;
            justify-content: space-between;
            column-gap: 36px;

            @media screen and (max-width: 1150px) {
                flex-direction: column;
                row-gap: 15px;
            }
        }

        .text-input-item {
            width: 100%;

            select option {
                color: #000;
            }
        }

        .text-input {
            height: 40px;
            width: 100%;
            padding-left: 10px;
            color: $grey;
            font-size: $fsize4;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: none;
            border: 2px #666 solid;

            &:hover {
                border-color: cyan;
            }

            &:focus {
                border-color: cyan;
                outline: none;
                // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6);
            }
        }

        .text-input-area {
            @extend .text-input;
            height: unset;
        }

        .token-info-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .radio-input-item {
            display: flex;
            flex-direction: column;
            gap: 5px;

            div {
                display: flex;
                align-items: center;

                .radio-input {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

        }

        .checkbox-input-item {
            display: flex;
            flex-direction: column;
            gap: 5px;

            div {
                display: flex;
                align-items: center;

                .radio-input {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

        }

        .btn-group {
            display: flex;
            justify-content: center;
            gap: 15px;
        }
    }
}

.staking-pool-list {
    width: min(90%, 1600px);
    margin: auto;
    margin-top: 0px;
    padding-bottom: 80px;

    .title {
        font-size: $fsize1;

        @media screen and (max-width: 900px) {
            text-align: center;
        }
    }

    .tab-switcher {
        display: flex;
        justify-content: center;
        align-items: center;

        .tab {
            padding: 0 20px;
            font-size: 20px;
            line-height: 2.5;
            cursor: pointer;
            border-bottom: 5px transparent solid;

            &:hover {
                background-color: #ffffff10;
            }
        }

        .tab-active {
            @extend .tab;
            color: $blue;
            border-bottom: 5px $blue solid;
        }
    }

}

.staking-pool-list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
        width: 90%;
    }

    .title {
        width: 80%;
        text-align: left;

        @media screen and (max-width: 900px) {
            text-align: center;
        }
    }

    .list-container {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;
    }
}

.staking-pool-details {
    width: min(90%, 1500px);
    max-width: 1500px;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 80px;

    @media screen and (max-width: 900px) {
        margin-top: 10px;
        flex-direction: column;
    }

    .title {
        font-size: $fsize1;

        @media screen and (max-width: 900px) {
            text-align: center;
        }
    }

    .content {
        width: 100%;

        .staking {
            margin-top: 30px;
            padding: 28px 36px;
            border: 2px transparentize($grey, 0.8) solid;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: flex-start;
            gap: 15px;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

            .title {
                font-size: $fsize2;
                text-align: center;
            }

            label {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    color: $pink;
                }
            }

            .text-input-group {
                display: flex;
                justify-content: space-between;
                column-gap: 36px;

                @media screen and (max-width: 1150px) {
                    flex-direction: column;
                    row-gap: 15px;
                }

                .left,
                .right {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    @media screen and (max-width: 900px) {
                        width: 100%;
                    }

                    .title {
                        font-size: $fsize3;
                    }
                }

                .left {
                    text-align: left;
                }

                .right {
                    text-align: right;
                }
            }

            .text-input-item {
                width: 100%;

                select option {
                    color: #000;
                }

                .warning-text {
                    color: $pink;
                }

                .tips-text {
                    color: $blue;
                }
            }

            .text-input {
                height: 40px;
                width: 100%;
                padding-left: 10px;
                color: $grey;
                font-size: $fsize4;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: none;
                border: 2px #666 solid;

                &:hover {
                    border-color: cyan;
                }

                &:focus {
                    border-color: cyan;
                    outline: none;
                    // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6);
                }
            }

            .div-line {
                width: 100%;
                background-color: $grey;
                height: 1px;
                opacity: 0.3;
            }

            .info-item {
                margin-top: 16px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
                gap: 10px;

                .key {
                    text-align: left;
                    min-width: 30%;
                }

                .value {
                    text-align: right;
                    max-width: 70%;
                }
            }

            .btn-group {
                display: flex;
                justify-content: center;
                gap: 15px;

                span {
                    color: cyan;
                }
            }

            .wallet-warning {
                width: 80%;
                margin-inline: auto;
                border: 2px #008000 solid;
                background: #00800022;
                color: cyan;
                font-size: $fsize3;
                line-height: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
            }
        }

        .owner-console {
            @extend .staking;

            .text-input-group {
                .btn-group {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .my-stakings {
            @extend .staking;

            .list-container {
                margin-top: 30px;
                display: flex;
                width: 80%;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;
                flex-wrap: wrap;

                @media screen and (max-width: 600px) {
                    width: 95%;
                    flex-direction: column;
                }

                .locker {
                    border-radius: 30px;
                    padding: 12px;
                    width: 270px;
                    border: 2px transparentize($grey, 0.8) solid;
                    border-radius: 15px;
                    box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@import "../config";

.create-launchpad {
    width: min(90%, 1500px);
    max-width: 1500px;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 80px;

    @media screen and (max-width: 900px) {
        margin-top: 10px;
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 30px;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
            justify-content: flex-start;
        }

        .step {
            width: 25%;
            display: flex;
            // justify-content: space-between;
            align-items: flex-start;
            gap: 10px;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            .step-number {
                width: 30px;
                min-width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #ffffff20;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .step-text {
                .description {
                    font-size: 12px;
                }
            }

        }

        .active-step {
            @extend .step;

            .step-number {
                background: $blue;
            }
        }
    }

    .div-line {
        width: 100%;
        background-color: $grey;
        height: 1px;
        opacity: 0.3;
    }

    .step-1 {
        margin-top: 30px;
        padding: 28px 36px;
        border: 2px transparentize($grey, 0.8) solid;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: flex-start;
        gap: 15px;
        box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .warning-text {
            color: $pink;
        }

        .tips-text {
            color: $blue;
        }

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: $pink;
            }
        }

        .text-input-group {
            display: flex;
            justify-content: space-between;
            column-gap: 36px;

            @media screen and (max-width: 1150px) {
                flex-direction: column;
                row-gap: 15px;
            }
        }

        .text-input-item {
            width: 100%;

            select option {
                color: #000;
            }
        }

        .text-input {
            height: 40px;
            width: 100%;
            padding-left: 10px;
            color: $grey;
            font-size: $fsize4;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: none;
            border: 2px #666 solid;

            &:hover {
                border-color: cyan;
            }

            &:focus {
                border-color: cyan;
                outline: none;
                // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6);
            }
        }

        .text-input-area {
            @extend .text-input;
            height: unset;
        }

        .token-info-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .radio-input-item {
            display: flex;
            flex-direction: column;
            gap: 5px;

            div {
                display: flex;
                align-items: center;

                .radio-input {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

        }

        .checkbox-input-item {
            display: flex;
            flex-direction: column;
            gap: 5px;

            div {
                display: flex;
                align-items: center;

                .radio-input {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

        }

        .btn-group {
            display: flex;
            justify-content: center;
            gap: 15px;
        }

        .ant-picker {
            height: 44px;
            width: 100%;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: none;
            border: 2px #666 solid;

            .ant-picker-input input {
                color: $grey;
                font-size: $fsize4;
            }

            &:hover {
                border-color: cyan;
            }

            &:focus {
                border-color: cyan;
                outline: none;
                // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6)
            }
        }
    }

    .step-2,
    .step-3,
    .step-4 {
        @extend .step-1;
    }

    .info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5em;

        .info {
            color: cyan;
        }
    }

}

.create-token {
    width: min(90%, 1500px);
    margin: auto;
    margin-top: 0px;
    padding-bottom: 80px;

    .tab-switcher {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tab {
            padding: 0 20px;
            font-size: 20px;
            line-height: 2.5;
            cursor: pointer;
            border-bottom: 5px transparent solid;

            &:hover {
                background-color: #ffffff10;
            }
        }

        .tab-active {
            @extend .tab;
            color: $blue;
            border-bottom: 5px $blue solid;
        }
    }

    .div-line {
        width: 100%;
        background-color: $grey;
        height: 1px;
        opacity: 0.3;
    }

    .create-field {
        margin-top: 100px;
        padding: 28px 36px;
        border: 2px transparentize($grey, 0.8) solid;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: flex-start;
        gap: 15px;
        box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);
        z-index: 1;

        @media screen and (max-width: 900px) {
            margin-top: 30px;
        }

        select option {
            color: black;
        }

        .warning-text {
            color: $pink;
        }

        .tip-text {
            color: $blue;
        }

        label {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            span {
                color: $pink;
            }
        }

        .text-input-group {
            display: flex;
            justify-content: space-between;
            column-gap: 36px;

            @media screen and (max-width: 1150px) {
                flex-direction: column;
                row-gap: 15px;
            }
        }

        .text-input-item {
            width: 100%;
        }

        .text-input {
            height: 40px;
            width: 100%;
            padding-left: 10px;
            color: $grey;
            font-size: $fsize4;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            border-radius: 10px;
            background: none;
            border: 2px #666 solid;

            &:hover {
                border-color: cyan;
            }

            &:focus {
                border-color: cyan;
                outline: none;
                // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6)
            }
        }

        .token-info-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .checkbox-input-item {
            display: flex;
            flex-direction: column;
            gap: 5px;

            div {
                display: flex;
                align-items: center;

                .radio-input {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

        }

        .btn-group {
            display: flex;
            justify-content: center;

        }
    }

    .manage-field {
        @extend .create-field;

        .token-list {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            font-family: Arial, Helvetica, sans-serif;

            .column {
                display: flex;
                align-items: center;
                text-align: center;
                line-height: 3;

                &:first-child {
                    color: cyan;
                    border-bottom: 2px transparentize($grey, 0.7) solid;
                }

                div:nth-child(1) {
                    width: 5%;
                }

                div:nth-child(2) {
                    width: 10%;
                }

                div:nth-child(3) {
                    width: 10%;
                }

                div:nth-child(4) {
                    width: 25%;
                }

                div:nth-child(5) {
                    width: 50%;
                }
            }
        }
    }
}

.launchpad-list {
    width: min(90%, 1600px);
    margin: auto;
    margin-top: 0px;
    padding-bottom: 80px;

    .title {
        font-size: $fsize1;
    }

    .tab-switcher {
        display: flex;
        justify-content: center;
        align-items: center;

        .tab {
            padding: 0 20px;
            font-size: 20px;
            line-height: 2.5;
            cursor: pointer;
            border-bottom: 5px transparent solid;

            &:hover {
                background-color: #ffffff10;
            }
        }

        .tab-active {
            @extend .tab;
            color: $blue;
            border-bottom: 5px $blue solid;
        }
    }

    .filter {
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 10px;

        label {
            font-size: $fsize4;
        }

        select {
            background: none;

            option {
                color: #000;
            }
        }

        .text-input {
            height: 40px;
            // width: 100%;
            padding-left: 10px;
            color: $grey;
            font-size: $fsize4;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: none;
            border: 2px #666 solid;

            &:hover {
                border-color: cyan;
            }

            &:focus {
                border-color: cyan;
                outline: none;
                // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6);
            }
        }
    }



    .list-container {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;
    }
}

.pool-details {
    width: min(96%, 1500px);
    max-width: 1500px;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 900px) {
        margin-top: 10px;
        flex-direction: column;
    }

    .info {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 20px;

        @media screen and (max-width: 900px) {
            width: 100%;
        }

        .project-description {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

            .info-top {
                display: flex;
                justify-content: stretch;
                align-items: flex-start;
                gap: 10px;

                .avatar {
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                }

                .content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 15px;

                    .top {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title {
                            font-size: 24px;
                            color: #fff;
                        }

                        .sale-live {
                            display: flex;
                            align-items: center;
                            line-height: 26px;
                            border-radius: 13px;
                            padding: 0px 8px;
                            background-color: rgb(209, 250, 229);
                            color: rgb(16, 185, 129);
                            font-size: $fsize6;
                            font-weight: 600;
                            white-space: nowrap;

                            span {
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background-color: rgb(16, 185, 129);
                                margin-right: 5px;
                            }
                        }

                        .sale-filled {
                            @extend .sale-live;
                        }

                        .sale-ended {
                            @extend .sale-live;
                            background-color: #feeaf1;
                            color: #f95192;

                            span {
                                background-color: #f95192;
                            }
                        }

                        .sale-upcomming {
                            @extend .sale-live;
                            background-color: rgb(253, 250, 234);
                            color: rgb(210, 152, 19);

                            span {
                                background-color: rgb(210, 152, 19);
                            }
                        }

                        .sale-canceled {
                            @extend .sale-live;
                            background-color: rgb(233, 234, 235);
                            color: rgb(189, 194, 196);

                            span {
                                background-color: rgb(189, 194, 196);
                            }
                        }
                    }

                    .middle {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 16px;

                        img {
                            height: 20px;
                            width: 20px;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }

                    .bottom {
                        width: 100%;
                        font-size: $fsize6;
                    }
                }

            }

            .youtube {
                padding: 2%;
            }
        }

        .basic-info {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

            .content {
                width: 100%;


                .info-item {
                    margin-top: 16px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 14px;

                    .key {
                        min-width: 30%;
                    }

                    .value {
                        text-align: right;
                        max-width: 70%;
                    }
                }
            }
        }

        .token-metrics {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

            .apexcharts-legend-text {
                color: white !important;
            }

            @media screen and (max-width: 900px) {
                padding: 50px 0;
            }
        }

        .whitelist {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

            .title {
                font-size: $fsize4;
                text-align: center;
            }

            .list-line {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                text-align: center;
                
                .left {
                    width: 10%;
                }

                .right {
                    width: 90%;
                }
            }

            .add-box {
                margin-top: 20px;

                .input-box {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    textarea {
                        // height: 30px;
                        width: 100%;
                        padding-left: 10px;
                        color: $grey;
                        font-size: $fsize4;
                        border-radius: 10px;
                        background: none;
                        border: 2px #666 solid;

                        &:hover {
                            border-color: cyan;
                        }

                        &:focus {
                            border-color: cyan;
                            outline: none;
                            // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6)
                        }
                    }
                }

                button {
                    margin-top: 16px;
                }

                .add-btn {
                    font-size: $fsize4;
                    font-weight: 900;
                    color: cyan;
                    padding: 0 10px;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    .actions {
        width: 38%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 20px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;

        @media screen and (max-width: 900px) {
            width: 100%;
        }

        .status-1 {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);
            padding-top: 10px;

            .title {
                width: 100%;
                font-size: $fsize3;
                text-align: center;
            }

            .time-state {
                width: 100%;
                text-align: center;
                font-size: $fsize4;
                margin-top: 12px;

                span {
                    color: $pink;
                    font-weight: 900;
                }
            }

            .progress-bar {
                width: 100%;
                position: relative;
                height: 8px;
                border-radius: 4px;
                border: 1px solid #aaa;
                margin-top: 16px;
                margin-bottom: 8px;

                .progress {
                    position: absolute;
                    left: 0;
                    // top: 0;
                    background-color: rgb(16, 185, 129);
                    height: 100%;
                }
            }

            .progress-detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .buy-box {
                margin-top: 20px;

                .title {
                    font-size: $fsize4;
                    text-align: left;
                }

                .input-box {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    input {
                        height: 30px;
                        width: 100%;
                        padding-left: 10px;
                        color: $grey;
                        font-size: $fsize4;
                        border-radius: 10px;
                        background: none;
                        border: 2px #666 solid;

                        &:hover {
                            border-color: cyan;
                        }

                        &:focus {
                            border-color: cyan;
                            outline: none;
                            // box-shadow: 0px 0px 10px rgba(18, 99, 241, 0.6)
                        }
                    }

                    .max-btn {
                        font-size: $fsize4;
                        font-weight: 900;
                        color: cyan;
                        padding: 0 10px;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.6;
                            border-radius: 5px;
                        }
                    }
                }

                button {
                    margin-top: 16px;
                }

            }

            .tips {
                width: 100%;
                color: $blue;
                text-align: center;
            }
        }

        .status-2 {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

            .option-item {
                margin-top: 16px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;

                .key {
                    min-width: 30%;
                }

                .value {
                    text-align: right;
                    max-width: 70%;
                }
            }
        }

        .status-3 {
            border-radius: 30px;
            padding: 2%;
            border: 2px transparentize($grey, 0.8) solid;
            box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);
            padding-top: 10px;

            .title {
                width: 100%;
                font-size: $fsize3;
                text-align: center;
            }

            button {
                margin-top: 16px;
            }

            .option-item {
                margin-top: 16px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;

                .key {
                    min-width: 30%;
                }

                .value {
                    text-align: right;
                    max-width: 70%;
                }
            }
        }

        .status-4 {
            @extend .status-3;
        }
    }


}
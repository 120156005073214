@import "../config";

.icon-button {
    height: 45px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    font-size: $fsize6;
    font-weight: 500;
    font-family: "Montserrat";
    border-radius: 8px;
    background: $blue;
    transition: background-color 0.2s ease-in-out 0s;

    &:hover {
        background-color: transparentize($blue, 0.2);
    }

    @media screen and (max-width: 900px) {
        height: 40px;
    }

    img {
        max-width: 30px;
        max-height: 30px;
    }
}

.small-icon-button {
    @extend .icon-button;
    height: 35px;

    @media screen and (max-width: 900px) {
        height: 35px;
    }

    img {
        max-width: 25px;
        max-height: 25px;
    }
}
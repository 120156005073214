$pink: #e91e63;
$yellow: #ff9400;
$blue: #1263f1;
$grey: #dddddd;
$black: #151521;


$fsize1: 40px;
$fsize2: 30px;
$fsize3: 25px;
$fsize4: 20px;
$fsize5: 16px;
$fsize6: 14px;
$fsize7: 13px;
@import "config";

.button {
    cursor: pointer;
}

a {
    color: white;
    text-decoration: none;
}

.hidden {
    display: none !important;
}

.text-white {
    color: white;
}

p {
    margin: 0;
}

button {
    border: none;
    &:disabled {
        cursor: not-allowed;
    }
}

.default-btn {
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    color: white;
    font-size: $fsize6;
    font-weight: 500;
    font-family: "Montserrat";
    border-radius: 8px;
    background: $blue;
    transition: background-color 0.2s ease-in-out 0s;
    cursor: pointer;

    &:hover {
        background-color: transparentize($blue, 0.2);
    }

    &:disabled  {
        cursor: not-allowed;
        background: $blue;
    }

    @media screen and (max-width: 900px) {
        // height: 40px;
    }
}

.small-icon-button {
    @extend .icon-button;
    height: 35px;

    @media screen and (max-width: 900px) {
        height: 35px;
    }
}

input[type="radio"], input[type="checkbox"] {
    cursor: pointer;
}

* {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

h1, h2, h3, h4, h5, h6 {
    color: white;
}
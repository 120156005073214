.staking-pool-card {
    border-radius: 30px;
    padding: 25px;
    width: 320px;
    border: 2px transparentize($grey, 0.8) solid;
    border-radius: 15px;
    box-shadow: 4px 4px 8px rgba(18, 99, 241, 0.6);

    @media screen and (max-width: 900px) {
        width: 100%;
    }

    .name {
        margin-top: 25px;
        font-size: $fsize4;
        font-weight: 600;
        color: white;
        text-align: center;
    }

    .info-line {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .key {
            margin-top: 0px;
            font-size: $fsize5;
            color: white;
        }

        .value {
            font-size: $fsize6;
            color: cyan;
        }
    }

    .divider {
        width: 100%;
        background-color: $grey;
        height: 1px;
        opacity: 0.3;
    }

    .btn-group {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        gap: 15px;
    }
}
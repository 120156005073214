@import "../config";

.sidebar-item {
    margin-top: 10px;
    height: 50px;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    position: relative;
    padding: 0px 12px;
    color: white;
    font-size: 14px;
    // font-family:'Times New Roman', Times, serif;
    font-family: "Montserrat";
    border-radius: 8px;
    background: transparent;
    transition: background .3s;

    &:hover {
        background-color: $pink;
    }
}

.sidebar-active-item {
    @extend .sidebar-item;
    background-color: $pink;
}

.sidebar-active-sub-item {
    @extend .sidebar-active-item;
    padding-left: 30px;
    font-size: 13px;
    color: $pink;
    background: transparent;
    transition: color .3s;

    &:hover {
        background: transparent;
    }
}

.sidebar-sub-item {
    @extend .sidebar-item;
    padding-left: 30px;
    font-size: 13px;
    transition: color .3s;

    &:hover {
        background: transparent;
        color: $pink;
    }
}